$assets-path: '../../assets' !default;

@mixin hover {
    &:hover {
        @media (-ms-high-contrast: none),
            (-ms-high-contrast: active),
            (-moz-touch-enabled: 0),
            (hover) {
            @content;
        }
    }
}

@mixin themed(
    $property,
    $properties: ('light', 'dark'),
    $dark-mode-selector: '.dark-mode'
) {
    @if (map-get($properties, light)) {
        #{$property}: map-get($properties, light);
    }

    @if (map-get($properties, dark)) {
        @at-root #{$dark-mode-selector} & {
            #{$property}: map-get($properties, dark);
        }
    }
}

@mixin flex(
    $align: stretch,
    $justify: space-between,
    $wrap: nowrap,
    $direction: row,
    $display: flex
) {
    display: $display;
    flex-flow: $direction $wrap;
    align-items: $align;
    justify-content: $justify;
}

@function v($var, $fallback: null) {
    @if ($fallback) {
        @return var(--#{$var}, #{$fallback});
    } @else {
        @return var(--#{$var});
    }
}

/**
    MUI Default Breakpoints ** start ** at:
    - xs: 0
    - sm: 600px (37.5rem)
    - md: 900px (56.25rem)
    - lg: 1200px (75rem)
    - xl: 1536px (96rem)
*/

$breakpoint-xs: 0;
$breakpoint-sm: 37.5rem;
$breakpoint-md: 56.25rem;
$breakpoint-lg: 75rem;
$breakpoint-xl: 96rem;
$breakpoint-xxl: 110rem;

@mixin large-desktop {
    @media (max-width: calc($breakpoint-xl - 1px)) {
        @content;
    }
}

@mixin xlarge-desktop {
    @media (max-width: calc($breakpoint-xxl - 1px)) {
        @content;
    }
}

@mixin medium-desktop {
    @media (max-width: calc($breakpoint-lg - 1px)) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: calc($breakpoint-md - 1px)) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: calc($breakpoint-sm - 1px)) {
        @content;
    }
}

// TODO - move to the shared library: EONFEH-15903
@mixin app-link($color: v(color-primary)) {
    color: $color;
    font-weight: bold;
    text-decoration: underline;

    @include hover {
        text-decoration: none;
    }
}

@mixin section-title {
    font-weight: 300;
    font-size: 1.625rem;
    margin: 1.25rem 0;
}

@mixin cancel-button {
    color: v(color-white);
    background-color: v(color-grey-button);
}

@mixin cancel-button-hover {
    background-color: v(color-grey-button);
}

@mixin animated-cancel-button {
    @include cancel-button;

    background: linear-gradient(
        to right,
        v(color-grey-hover) 50%,
        v(color-grey-button) 50%
    );
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all ease 1s;

    &:hover {
        @include cancel-button-hover;
        background-position: left bottom;
    }
}

@mixin close-button-position {
    position: absolute;
    top: 0;
    right: 0;
}

@mixin auto-height {
    height: auto !important;
}

$fonts-path: '@eon-home/react-library/src/assets/fonts';
$assets-path: '@eon-home/react-library/src/assets';

@import '@fontsource/roboto/300.css';
@import '@fontsource/roboto/400.css';
@import '@fontsource/roboto/500.css';
@import '@fontsource/roboto/700.css';

// TODO: Create a combined entrypoint for the CSS in the shared library: EONFEH-15903
@import '@eon-home/react-library/src/assets/fonts.scss';
@import '@eon-home/react-library/src/assets/variables.scss';
@import '@eon-home/react-library/src/components/icon/index.scss';
@import '@eon-home/react-library/src/components/button/index.scss';
@import '@eon-home/react-library/src/components/loader/index.scss';
@import '@eon-home/react-library/src/components/switch/index.scss';
@import '@eon-home/react-library/src/components/dropdown/index.scss';
@import '@eon-home/react-library/src/components/app-prompt/index.scss';
@import '@eon-home/react-library/src/components/form-components/index.scss';
@import '@eon-home/react-library/src/components/mobile-landing/index.scss';

:root {
    --color-white: #fff;
    --color-white-hover: #f5f5f5;
    --color-smoke: #dcdcdc;
    --color-grey: #999999;
    --color-grey-hover: #747474;
    --color-grey-light: #8a96a0;
    --color-grey-border: #e6eaee;
    --color-grey-readonly: #ededed;
    --color-grey-button: #8c8c8c;
    --color-medium-dark-grey: #666;
    --color-dark-grey: #303030;

    --color-black: #000000;
    --color-hover-black: #0000000a;
    --color-text-black: #212121;
    --color-black-light: #000000de;

    --color-orange: #f39f53;
    --color-yellow: #f3ee7a;

    --color-red: #ea1b0a;
    --color-bordeaux: #cd1d1b;

    --color-admin-blue: #9bbdd6;
    --color-status-blue: #3aa4d2;

    --color-status-green: #50d166;
    --color-status-yellow: #e3e000;
    --color-status-gray: #cccccc;
    --color-light-gray: #f5f5f5;

    --color-sub-blue: #1ea2b1;
    --color-sub-red: #fe136a;

    --color-sub-type-orange: #ce8c53;
    --color-sub-type-green: #30c164;
    --color-sub-type-darkgreen: #63b75d;
    --color-sub-type-yellow: #c3c00a;
    --color-sub-type-purple: #9013fe;
    --color-sub-type-lightorange: #f5a623;
    --color-sub-type-ocean: #50e3c2;

    --site-menu-width: 20rem;
}

.c-btn {
    text-decoration: none;

    &--reset {
        position: absolute;
        top: 50%;
        left: 6.25rem;
        transform: translateY(-50%);
    }
}

html,
body,
#root {
    height: 100%;
    background-color: #fafafa;
}

@include medium-desktop {
    .header-left {
        margin-left: -19.5rem;
    }

    .main-menu {
        margin-left: calc(-1 * var(--site-menu-width));
    }

    .menu-toggle {
        display: flex !important;
    }

    .account-email {
        display: none;
    }

    .is--expanded {
        .main-menu,
        .header-left {
            margin-left: 0;
        }

        .menu-toggle {
            transform: translateY(-50%) rotate(-180deg);
        }
    }
}

@include tablet {
    :root {
        --timing: 0.2s;
        --easing: linear;
    }

    .is--expanded {
        .main-menu {
            margin-left: 0;
        }

        .header-left {
            --arrow-icon-size: 4rem;
            width: calc(var(--site-menu-width) - var(--arrow-icon-size));
            flex-shrink: 0;
        }

        .content {
            display: none;
        }
    }
}
